<template>
  <!--  <router-link :to="link" class="plus-btn">-->
  <!--&lt;!&ndash;    <img src="../resource/img/plus-big.svg" alt="icon" class="">&ndash;&gt;-->
  <!--    Открыть чат-->
  <!--  </router-link>-->

  <!--  <button class="plus-btn">-->
  <!--    -->
  <!--  </button>-->
  <div>
    <b-button class="plus-btn" id="popover-target-1" @click="showModal"
      >Launch modal</b-button
    >
    <b-popover
      target="popover-target-1"
      triggers="hover"
      placement="top"
      v-if="message"
    >
      <div class="p-2">
        {{ message }}
      </div>
    </b-popover>

    <b-modal
      ref="modal"
      id="modal-1"
      modal-class="plus-btn-modal"
      dialog-class="modal-chat-modal"
      :title="isLoading ? 'Запрос обрабатывается' : modalTitle"
      content-class="modal-chat"
      header-class="modal-chat__header"
      footer-class="modal-chat__footer"
    >
      <div class="plus-btn__btn-chat-wrap" v-if="!isLoading">
        <a
          v-for="(item, index) in types"
          :key="item.id"
          @click="onItemClick(item.id)"
          class="btn btn-primary chat-btn"
        >
          {{ index + 1 }}. {{ item.title }}
        </a>
      </div>

      <div v-else class="text-center mb-3">
        <b-spinner type="grow" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import RequestManager from "../function/request/RequestManager";
import { OrdersApi } from "../function/request/apiV2";
export default {
  name: "PlusButton",
  props: {
    link: String,
    oldorder: String,
    device: Object,
    devices: {
      type: Array,
      default: () => []
    },
    message: String,
    modalTitle: {
      default: "Выберете тип вопроса",
      type: String
    }
  },
  computed: {
    ...mapGetters(["currentUser"])
  },
  created() {
    /*  RequestManager()
      .getOrdersType()
      .then(data => {
        this.types = data.types;
         console.log("types***",  this.types)
      });   
*/

    this.getTypes();
  },
  data() {
    return {
      ordersApi: new OrdersApi(),
      history: {},
      types: [],
      isLoading: false
    };
  },
  methods: {
    async getTypes() {
      try {
        this.types = await this.ordersApi.api.getOrderTypes();
        this.types = this.types.reverse();
      } catch (e) {
        throw new Error(e);
      }
    },

    showModal() {
      this.$bvModal.show("modal-1");
    },
    onItemClick(id) {
      this.isLoading = true;
      // Не понятно для какой цели получается история чата, в дальнейшем она нигде не используется
      // RequestManager()
      //   .getOrdersChatHistory(id)
      //   .then(data => {
      //     this.history = data;
      //   });
      const data = {
        title: this.types.find(elem => elem.id === id).title,
        company: this.currentUser.company,
        author: this.currentUser.id,
        type: this.types.find(elem => elem.id === id).id,
        devices: this.$route.params.id,
        current_status: 2
      };
      // if (this.device !== null && this.device !== undefined) {
      //   // data.devices = [{ id: this.$route.params.id, ...this.device }];
      //   // data.ID = this.$route.params.id;
      //   data.device_ids = [+this.$route.params.id];
      // }
      // if (this.devices?.length) {
      //   data.devices = this.devices?.map(el => el.id);
      // }
      /* RequestManager()
        .createNewOrder(data)
        .then(data => {
          this.$bvModal.hide("modal-1");
          if (data?.success === false) {
            this.$bvToast.toast("Возникла непредвиденная ошибка", {
              variant: "danger",
              title: "Ошибка"
            });
          } else {
            this.$emit("add-order", data);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });*/

      // if (this.oldorder !== "true") {
      this.$router.push({
        name: "RequestCreate",
        params: {
          title: this.device.title,
          deviceId: data.devices,
          typeId: data.type
        }
      });
      // }
      // if (this.oldorder == "true") {
      //   RequestManager()
      //     .createNewOrder(data)
      //     .then(data => {
      //       this.$bvModal.hide("modal-1");
      //       if (data?.success === false) {
      //         this.$bvToast.toast("Возникла непредвиденная ошибка", {
      //           variant: "danger",
      //           title: "Ошибка"
      //         });
      //       } else {
      //         this.$emit("add-order", data);
      //       }
      //     })
      //     .finally(() => {
      //       this.isLoading = false;
      //     });
      // }
    }
  }
};
</script>
<style lang="scss">
.fc-event-title {
  margin-left: 0 !important;
}
</style>
<style lang="scss" scoped>
.chat-btn {
  border: 1px solid #006b90;
  margin-bottom: 10px;
  padding: 16px 25px;
  &:hover {
    padding: 15px 25px;
    border: 2px solid #1cdcfc;
    box-sizing: border-box !important;
  }
}
.plus-btn {
  position: absolute;
  bottom: 45px;
  right: 36px;

  display: block;

  width: 80px;
  height: 80px;

  background: #006b90;
  border-radius: 50%;

  font-size: 0;

  border: 0;

  &::before {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;

    width: 50px;
    height: 50px;

    background-image: url("../resource/img/plus-big.svg");
    background-repeat: no-repeat;
    background-position: center;
  }

  &:hover,
  &:focus {
    background: lighten(#006b90, 10%);
  }
}

.plus-btn__btn-chat-wrap {
  // height: 225px;
  display: flex;
  padding-top: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box !important;
  a {
    box-sizing: border-box !important;
  }
}
</style>
