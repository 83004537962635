<template>
  <TepmplateBlock mod-class content-class="chat-wrap mt-4" title-page="Задачи">
    <help-popover
      v-if="helpPopovers.PopoverOfTitlePage"
      :message="helpPopovers.PopoverOfTitlePage"
      class="ml-2 help-icon"
      :is-inline-block="false"
      style="right: calc(50% + 57px); top: 15px"
    />
    <template #titleAppend>
      <IconPlus @clicked="createRequest" />
      <help-popover
        v-if="helpPopovers.PopoverOfTitlePageIcon"
        :message="helpPopovers.PopoverOfTitlePageIcon"
        class="ml-2 help-icon"
        :is-inline-block="false"
        style="right: calc(50% + 15px); top: 15px"
      />
    </template>
    <div class="chat-wrap">
      <help-popover
        v-if="helpPopovers.PopoverInformationAbout"
        :message="helpPopovers.PopoverInformationAbout"
        class="ml-2 help-icon"
        :is-inline-block="false"
        style="position: relative; top: -10px; left: -7px"
      />
      <div class="scroll-container-orders">
        <vuescroll @handle-scroll="handleOrdersScroll">
          <div class="chat-users">
            <!--            <div-->
            <!--              @click="onManagerLinkClick"-->
            <!--              class="my-request__item manager mb-3"-->
            <!--              :class="{ active: activeOrder === -1 }"-->
            <!--            >-->
            <!--              <div>-->
            <!--                <img-->
            <!--                  :src="-->
            <!--                    activeOrder === -1 ? manager.activeLink : manager.imgLink-->
            <!--                  "-->
            <!--                  alt=""-->
            <!--                  class="manager-image"-->
            <!--                />-->
            <!--              </div>-->
            <!--              <div>-->
            <!--                <h3 class="my-request__title">{{ manager.text }}</h3>-->
            <!--              </div>-->
            <!--            </div>-->

            <div
              v-for="(item, index) in orders"
              :key="index"
              class="my-request__item"
              :class="{ active: activeOrder === index }"
              @click="onOrderClick(index, item.id)"
            >
              <div class="divider"></div>
              <div>
                <h3 class="">{{ item.title }} (№{{ item.id }})</h3>
                <p v-if="item.current_status !== null">
                  <span class="font-weight-bold">Статус:</span>
                  <span :class="getClass(item.current_status)">
                    {{ item.current_status.title }}
                  </span>
                </p>

                <div v-for="(device, index) in item.devices" :key="index">
                  <p class="mb-0" v-if="device.office">
                    Офис:
                    <span class="bold">{{ device.office.office_name }}</span>
                  </p>

                  <p class="mb-0">
                    Название: <span class="bold"> {{ device.title }}</span>
                  </p>
                  <p class="mb-0 fake-link">
                    Серийный/стикер:
                    <span class="bold ml-2">{{ device.serial_num }}</span>
                    <span class="bold" v-if="device.sticker_num">
                      / {{ device.sticker_num }}</span
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </vuescroll>
      </div>

      <div class="chat">
        <div class="my-request__item title position-relative">
          <help-popover
            v-if="helpPopovers.PopoverStatus && statuses.length > 0"
            :message="helpPopovers.PopoverStatus"
            class="ml-2 help-icon"
            :is-inline-block="false"
            style="top: 55px; left: 183px"
          />

          <div class="divider"></div>
          <div
            v-if="currentOrderInfo !== undefined"
            class="d-flex justify-content-between w-100 align-items-center mr-2"
          >
            <div>
              <h3 class="">
                {{ currentOrderInfo.title }}
                <help-popover
                  v-if="helpPopovers.PopoverStatusName && statuses.length > 0"
                  :message="helpPopovers.PopoverStatusName"
                  class="ml-2 help-icon"
                  :is-inline-block="true"
                  style="position: relative; bottom: 10px; right: 10px"
                />
              </h3>
              <p v-if="statuses.length > 0">
                Статус:
                <span
                  v-if="currentOrderInfo && currentOrderInfo.status"
                  :class="getClass(currentOrderInfo.current_status)"
                >
                  {{ statuses[statuses.length - 1].description }}
                </span>
                <span v-else>Без статуса</span>
              </p>
            </div>
            <div
              @click="onSerialClick"
              v-if="currentOrderInfo.devices.length"
              class="serial_number"
            >
              {{ currentOrderInfo.devices[0].serial_num }}
            </div>
          </div>
          <div v-else>
            <h3>Чат с менеджером</h3>
            <div>
              Задайте все вопросы сюда и оперативно получите ответы в чате
            </div>
          </div>
        </div>
        <div v-if="page === 'RequestChat'" class="status-block">
          <h5 class="mb-3 ml-1">
            История задачи
            <span
              class="c-pointer"
              v-if="statusArray.length > 3"
              @click="showAllStatuses = !showAllStatuses"
              style="position: relative;"
              >...</span
            >
            <help-popover
              v-if="helpPopovers.PopoverOfHistory"
              :message="helpPopovers.PopoverOfHistory"
              class="ml-2 help-icon"
              :is-inline-block="true"
              style="position: relative; bottom: 10px; right: 10px"
            />
          </h5>
          <div class="statuses-wrapper">
            <div
              class="status-item"
              v-for="(item, index) in statuses"
              :key="index"
            >
              <div class="status-item__checked-wrapper">
                <div v-if="index > 0" class="status-item__line"></div>
                <div
                  class="status-item__checked"
                  :class="{ 'not-checked': statuses.length - 1 === index }"
                >
                  <div class="checkline"></div>
                </div>
              </div>
              <div class="status-item__title">
                <div
                  class="item-title"
                  :class="{
                    'not-checked-title': statuses.length - 1 === index
                  }"
                >
                  {{ item.description }}{{ item.title }}
                </div>
                <div>
                  - {{ item.time }} {{ item.date }}
                  {{
                    item.timestamp !== undefined &&
                      getTime(item.timestamp) +
                        ", " +
                        item.timestamp.slice(0, 10)
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="chat__list position-relative"
          :class="{ manager: page === 'ManagerChat' }"
        >
          <help-popover
            v-if="helpPopovers.PopoverOfChatAboutInformation"
            :message="helpPopovers.PopoverOfChatAboutInformation"
            class="ml-2 help-icon"
            :is-inline-block="true"
            style="position: absolute; top: 0; left: 0"
          />
          <div class="scroll-container-messages">
            <vuescroll ref="messagesContainer" @handle-scroll="handleScroll">
              <div class="chat__list">
                <div
                  @click="saveFile(message)"
                  v-for="message in chatMessages"
                  :key="message.id"
                  class="d-flex justify-content-end flex-column"
                >
                  <div
                    class="text-center mt-3 mb-3 check-new-date"
                    v-if="checkNewDay(message)"
                  >
                    {{ checkNewDay(message) }}
                  </div>
                  <div
                    :class="
                      currentUser.id === message.author
                        ? message.file === null
                          ? 'chat__you-msg'
                          : 'chat__you-msg with-file'
                        : message.file === null
                        ? 'chat__other-msg'
                        : 'chat__other-msg with-file'
                    "
                  >
                    <img
                      v-if="message.file"
                      :src="
                        currentUser.id === message.author
                          ? fileOwnIcon
                          : fileIcon
                      "
                      class="file-icon"
                      alt="file"
                    />
                    {{ message.text }}
                    <div
                      class="date-label"
                      v-if="message.timestamp"
                      :title="getFullDate(message.timestamp)"
                    >
                      {{ getDate(message.timestamp) }}
                    </div>

                    <div class="date-label mt-1" v-else>
                      <img :src="clockIcon" alt="Отправка" />
                    </div>
                  </div>
                </div>
              </div>
            </vuescroll>
          </div>
        </div>
        <div class="chat__bottom-panel">
          <div class="chat__attachment-block " title="Добавить файл">
            <label class="file-select">
              <div @click="onModalBtnClick" class="select-button"></div>
              <help-popover
                v-if="helpPopovers.PopoverOfAttachFile"
                :message="helpPopovers.PopoverOfAttachFile"
                class="ml-2 help-icon"
                :is-inline-block="false"
                style="left: 23px; bottom: 20px"
              />
              <!-- <input type="file" @change="handleFileChange" /> -->
            </label>
          </div>
          <input
            ref="messageInput"
            @keydown="onKeyDown"
            class="chat__make-msg"
            type="text"
            placeholder="Введите сообщение"
            v-model="message"
          />
          <help-popover
            v-if="helpPopovers.PopoverOfAreaToWriteMessages"
            :message="helpPopovers.PopoverOfAreaToWriteMessages"
            class="ml-2 help-icon"
            :is-inline-block="false"
            style="position: relative; transform: rotate(180deg); bottom: -20px"
          />
          <div class="chat__send-button c-pointer" @click="onKeyDown">
            Отправить
            <help-popover
              v-if="helpPopovers.PopoverOfSendingMessages"
              :message="helpPopovers.PopoverOfSendingMessages"
              class="ml-2 help-icon"
              :is-inline-block="true"
              style="left: 97px; bottom: 25px"
            />
          </div>
        </div>
      </div>

      <b-modal id="modal-file" centered title="Добавить файл">
        <div class="d-flex">
          <div class="modal-button">
            Выберите файл
            <b-form-file v-model="file" class="mt-3 file" plain></b-form-file>
          </div>
          <span class="file-label ml-2">{{ file ? file.name : "" }}</span>
        </div>
        <label class="file-label">Описание</label>
        <b-form-input v-model="fileDescription" trim></b-form-input>

        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              size="sm"
              class="modal-button"
              @click="addFileClick"
              title="Прикрепление файла"
            >
              Добавить файл
            </b-button>
          </div>
        </template>
      </b-modal>
    </div>
    <help-popover
      :message="helpPopovers.PopoverOfIconPlus"
      style="position: absolute; right: 25px; bottom: 100px"
    />
    <PlusButton
      ref="plusButton"
      @add-order="onAddOrder"
      title="Создание новой задачи.
      Задачи созданные тут - не имеют привязки к чему-то
      и требуют описание проблемы в чате"
      link="/my-techique/chat"
    />
  </TepmplateBlock>
</template>

<script>
import TepmplateBlock from "../../components/TepmplateBlock";
import RequestManager from "../../function/request/RequestManager";
import PlusButton from "../../components/PlusButton";

import { mapGetters } from "vuex";
import vuescroll from "vuescroll";
import IconPlus from "../../components/Form/IconPlus";
import helpPopover from "../../components/HelpPopover";
// import { saveAs } from "file-saver";
export default {
  name: "Chat",
  components: { IconPlus, TepmplateBlock, vuescroll, PlusButton, helpPopover },
  props: {
    value: File,
    topic: {
      type: String
    }
  },
  data() {
    return {
      helpPopovers: {
        PopoverOfTitlePage:
          "Раздел задачи, здесь можно найти все или отфильтровать нужные задачи",
        PopoverOfTitlePageIcon: "Кнопка создания новой задачи",
        PopoverStatus: "Статус задачи",
        PopoverStatusName: "Название задачи",
        PopoverOfAttachFile: "Отправить(прикрепить к чату) файл",
        PopoverOfSendingMessages: "Кнопка отправки сообщения",
        PopoverOfAreaToWriteMessages:
          "Поле для сообщения (при нажатии на кнопку Enter, произойдет отправка сообщения)",
        PopoverOfIconPlus: "Кнопка создания новой задачи",
        PopoverOfHistory: "История изменений статуса задачи",
        PopoverInformationAbout:
          "Все задачи, можно быстро перейти в нужную, выше находятся те задачи, в которых были изменения",
        PopoverOfChatAboutInformation:
          "Чат задачи, в котором отражается все общение"
      },
      ordersCount: 0,
      orderClicked: false,
      interval: null,
      fileIcon: require("../../resource/img/file.svg"),
      fileOwnIcon: require("../../resource/img/own-file.svg"),
      clockIcon: require("../../resource/img/clock.svg"),
      managerActive: false,
      chatScrollOptions: {},
      mainCompanyId: null,
      file: null,
      fileDescription: "",
      manager: {
        text: "Ваш персональный менеджер",
        model: "",
        link: "/manager/chat",
        imgLink: require("../../resource/img/personal-man.svg"),
        activeLink: require("../../resource/img/personal-man-active.svg")
      },
      statusArray: [],
      chatMessages: [],
      chatId: 0,
      message: "",
      messagesCount: 0,
      active: true,
      activeOrder: 0,
      orders: [],
      activeStatusIndex: 2,
      showAllStatuses: false,
      currentOrderInfo: undefined
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
    page() {
      return this.$route.name;
    },
    chatRouteId() {
      return this.$route.params.id;
    },
    container() {
      return this.$refs.messagesContainer;
    },
    statuses() {
      let reverseStatuses = [...this.statusArray].reverse();
      return reverseStatuses &&
        reverseStatuses.length > 4 &&
        !this.showAllStatuses
        ? reverseStatuses.splice(-3)
        : reverseStatuses;
    }
  },
  watch: {
    chatRouteId(id) {
      if (this.page === "RequestChat") {
        RequestManager()
          .getOrdersChat(id)
          .then(data => {
            this.chatMessages = data.results;
            this.messagesCount = data.count;
            setTimeout(() => {
              this.container.scrollTo({ y: "100%" }, 100);
            }, 500);
          });
        RequestManager()
          .getOrdersChatHistory(this.$route.params.id)
          .then(data => (this.statusArray = data.results));

        RequestManager()
          .getOrderById(this.$route.params.id)
          .then(order => {
            this.currentOrderInfo = order;
          });
      } else {
        this.fetchManagerChat();
        this.container.scrollTo({ y: "100%" }, 100);
      }
    }
  },
  created() {
    if (this.page === "RequestChat") {
      this.fetchRequestChat();
      let elIndex = 0;
      this.orders.forEach((elem, index) => {
        if (elem.id === +this.$route.params.id) {
          elIndex = index;
        }
      });
      this.activeOrder = elIndex;
    }
    if (this.page === "ManagerChat") {
      this.fetchManagerChat();
      this.activeOrder = -1;
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  mounted() {
    this.interval = setInterval(() => {
      if (this.activeOrder === -1) {
        RequestManager()
          .getMainCompanyChat(this.currentUser.company)
          .then(data => {
            if (this.messagesCount !== data.count) {
              this.chatMessages = data.results;
              this.messagesCount = data.count;
              this.container.scrollTo({ y: "100%" }, 100);
              if (
                localStorage.getItem(
                  `order_${this.$route.params.id}_chat_count`
                )
              ) {
                localStorage.setItem(
                  `order_${this.$route.params.id}_chat_count`,
                  `${data.count}`
                );
              } else {
                localStorage.setItem(
                  `order_${this.$route.params.id}_chat_count`,
                  `0`
                );
              }
            }
          });
      } else {
        RequestManager()
          .getOrdersChat(this.chatId)
          .then(data => {
            if (this.messagesCount !== data.count) {
              this.chatMessages = data.results;
              this.messagesCount = data.count;
              this.container.scrollTo({ y: "100%" }, 100);
            }
          });
      }
    }, 10000);
    this.$refs.messageInput.focus();
    setTimeout(() => {
      this.container.scrollTo(
        {
          y: "100%"
        },
        100
        // "easeInQuad"
      );
    }, 500);
  },
  methods: {
    createRequest() {
      this.$refs.plusButton.showModal();
    },
    getAuthor(item) {
      let label = "Не указан";
      if (item.author_object && item.author_object.email) {
        label =
          item.author_object.first_name.length > 2 &&
          item.author_object.last_name.length > 2
            ? `${item.author_object.first_name} ${item.author_object.last_name}`
            : item.author_object.email;
      }
      return label;
    },
    getDate(date) {
      let options = {
        hour: "numeric",
        minute: "numeric"
      };
      return new Date(date).toLocaleString("ru-RU", options);
    },
    getFullDate(date) {
      return new Date(date).toLocaleString();
    },
    checkNewDay(message) {
      let date;
      if (message && message.timestamp) {
        const index = this.chatMessages.indexOf(message);
        const messageTime = new Date(message.timestamp).toLocaleDateString();
        const previousChatMessage = this.chatMessages[index + 1];
        if (previousChatMessage && messageTime) {
          let previousChatMessageDatetime = new Date(
            previousChatMessage.timestamp
          ).toLocaleDateString();
          if (messageTime === previousChatMessageDatetime) {
            date = false;
          } else {
            date = messageTime;
          }
        } else {
          date = messageTime;
        }
      }
      return date;
    },
    onSerialClick() {
      const id = this.currentOrderInfo?.devices[0]?.id;
      this.$router.push({ path: "/my-techique/" + id });
    },
    onAddOrder(data) {
      this.orders = [data, ...this.orders];
      this.activeOrder = 0;
      const path = "/my-request/chat/" + data.id;
      this.$router.push({ path });
    },
    getTime(date) {
      const time = new Date(date);
      const hours = time.getHours();
      const minutes =
        time.getMinutes().toString().length === 1
          ? "0" + time.getMinutes()
          : time.getMinutes();

      return hours + ":" + minutes;
    },
    handleOrdersScroll(vertical) {
      if (vertical.process === 1 && this.orders.length < this.ordersCount) {
        RequestManager()
          .getOrdersList(this.orders.length)
          .then(data => {
            this.orders = [...this.orders, ...data.results];
            this.ordersCount = data.count;
          });
      }
    },
    saveFile(message) {
      if (message.file) {
        let link = document.createElement("a");
        link.href = process.env.VUE_APP_URL + message.file;
        link.target = "_blank";
        link.click();

        // saveAs(
        //   process.env.VUE_APP_URL + message.file,
        //   decodeURI(message.file.replace("/media/chat/", ""))
        // );
      }
    },
    onModalBtnClick() {
      this.$bvModal.show("modal-file");
    },
    addFileClick() {
      const headers = {
        "Content-Type": "multipart/form-data"
      };
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("author", this.currentUser.id);
      if (this.fileDescription.length > 0) {
        formData.append("text", this.fileDescription);
      }
      if (this.activeOrder === -1) {
        formData.append("company", this.currentUser.company);
        RequestManager()
          .sendMainCompanyChatMessage(
            this.currentUser.company,
            formData,
            headers
          )
          .then(data => {
            this.$bvModal.hide("modal-file");
            this.file = null;
            this.fileDescription = "";
            this.chatMessages = [data, ...this.chatMessages];
            setTimeout(() => {
              this.container.scrollTo({ y: "100%" }, 100);
            }, 100);
          });
      } else {
        RequestManager()
          .sendOrdersChatMessage(this.chatId, formData, headers)
          .then(data => {
            this.$bvModal.hide("modal-file");
            this.file = null;
            this.fileDescription = "";
            this.chatMessages = [data, ...this.chatMessages];
            setTimeout(() => {
              this.container.scrollTo({ y: "100%" }, 100);
            }, 100);
          });
      }
    },
    fetchManagerChat() {
      RequestManager()
        .getMainCompanyChat(this.currentUser.company)
        .then(data => {
          this.chatMessages = data.results;
          this.messagesCount = data.count;
          this.activeOrder = -1;
          setTimeout(() => {
            this.container.scrollTo({ y: "100%" }, 100);
          }, 500);
        });
      RequestManager()
        .getOrdersList()
        .then(data => {
          const orders = data.results.map(elem => {
            return { ...elem, status: 0 };
          });
          this.orders = orders;
        });
    },
    fetchRequestChat() {
      RequestManager()
        .getOrdersChatHistory(this.$route.params.id)
        .then(data => {
          this.statusArray = data.results;
        });

      RequestManager()
        .getOrderById(this.$route.params.id)
        .then(order => {
          this.currentOrderInfo = order;
        });

      RequestManager()
        .getOrdersList()
        .then(data => {
          const orders = data.results.map(elem => {
            return { ...elem, status: 0 };
          });
          this.orders = orders;
          this.ordersCount = data.count;
          this.$set(this, "chatId", this.$route.params.id);
          data.results.forEach((item, index) => {
            if (item.id == this.$route.params.id) {
              this.activeOrder = index;
            }
          });
        });
      RequestManager()
        .getOrdersChat(this.$route.params.id)
        .then(data => {
          this.chatMessages = data.results;
          this.messagesCount = data.count;
        });
    },
    handleFileChange(e) {
      this.$emit("input", e.target.files[0]);
    },
    getClass(status) {
      if (status === null) return "yellow";
      return status.id === 1 ? "yellow" : "blue";
    },
    getStatus(status) {
      if (status === 0) {
        return "в обработке";
      } else {
        return "на обслуживании";
      }
    },
    onKeyDown(event) {
      const isPressButton = event.target.className.includes(
        "chat__send-button"
      );
      if (
        (isPressButton || event.key === "Enter") &&
        this.message &&
        this.message.length >= 1
      ) {
        const messageForRequest = this.message;
        const data = {
          author: this.currentUser.id,
          text: messageForRequest
        };
        if (this.page === "RequestChat") {
          data.order = this.chatId;
          this.chatMessages = [
            {
              author: this.currentUser.id,
              text: messageForRequest,
              localMessage: true
            },
            ...this.chatMessages
          ];
          this.message = "";
          RequestManager()
            .sendOrdersChatMessage(this.$route.params.id, data)
            .then(() => {
              this.chatMessages = this.chatMessages.filter(el => {
                return !el.localMessage;
              });
              this.chatMessages = [
                { author: this.currentUser.id, text: messageForRequest },
                ...this.chatMessages
              ];
              setTimeout(() => {
                this.container.scrollTo({ y: "100%" }, 100);
              }, 100);
            });
        }
        if (this.page === "ManagerChat") {
          this.chatMessages = [
            {
              author: this.currentUser.id,
              text: messageForRequest,
              localMessage: true
            },
            ...this.chatMessages
          ];
          this.message = "";
          RequestManager()
            .sendMainCompanyChatMessage(this.currentUser.company, {
              ...data,
              company: this.currentUser.company
            })
            .then(data => {
              this.message = "";
              this.chatMessages = this.chatMessages.filter(el => {
                return !el.localMessage;
              });
              this.chatMessages = [data, ...this.chatMessages];
              setTimeout(() => {
                this.container.scrollTo({ y: "100%" }, 100);
              }, 100);
            });
        }
      }
    },
    onOrderClick(index, id) {
      if (this.orderClicked === false) {
        this.activeOrder = index;
        this.chatId = id;
        this.$router.push({ path: "/my-request/chat/" + id });
        this.$refs.messageInput.focus();
        this.orderClicked = true;
        setTimeout(() => {
          this.orderClicked = false;
        }, 100);
      }
    },
    onManagerLinkClick() {
      this.activeOrder = -1;
      this.$router.push({ path: "/manager/chat" });
      this.$refs.messageInput.focus();
    },
    validate() {},
    handleScroll(vertical) {
      if (vertical.scrollTop === 0) {
        if (this.chatMessages.length < this.messagesCount) {
          if (this.page === "RequestChat") {
            RequestManager()
              .getOrdersChat(this.chatId, this.chatMessages.length)
              .then(data => {
                this.chatMessages = [...this.chatMessages, ...data.results];
                this.container.scrollTo({ y: "100%" }, 100);
              });
          } else {
            RequestManager()
              .getMainCompanyChat(
                this.currentUser.company,
                this.chatMessages.length
              )
              .then(data => {
                this.chatMessages = [...this.chatMessages, ...data.results];
                this.container.scrollTo({ y: "100%" }, 100);
              });
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.c-pointer {
  cursor: pointer;
}

.chat__send-button {
  transform: rotate(180deg);
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 20px;
  color: white;
  background-color: #006b90;
}

.statuses-wrapper {
  max-height: 121px;
  overflow: auto;
  padding: 5px;
}

.serial_number {
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.04em;
  color: black;
  font-weight: bold;
}
.with-file {
  cursor: pointer;
}
.__vuescroll {
  height: 100% !important;
}
.file-label {
  margin: 8px 0 0 0;
  letter-spacing: 0.04em;
  color: #006b90;
  font-weight: bold;
  font-size: 14px;
}
.modal-button {
  position: relative;
  padding: 10px;
  width: 150px;
  border: 1px solid #006b90;
  letter-spacing: 0.04em;
  color: #006b90;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  border-radius: 0.2em;
  outline: none;
  cursor: pointer;
  .file {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0 !important;
    width: 150px;
    height: 100%;
    margin: 0;
    opacity: 0;
  }
}

.scroll-container-orders {
  overflow: hidden;
  height: 100%;
}
.scroll-container-messages {
  height: 100%;
}
.status-item {
  display: flex;
  align-items: flex-end;
  .status-item__title {
    position: relative;
    top: 5px;
    display: flex;
    align-items: center;
  }
  .item-title {
    display: flex;
    align-items: center;
    margin: 0 5px 0 10px;
    border-radius: 10px;
    color: #006b90;
    font-weight: 600;
    font-size: 14px;
    padding: 5px 10px;
    background: var(--main-card-color);
  }
  .item-title.not-checked-title {
    color: #fcbc03;
    background: var(--main-text-color-inverse);
  }
  &__checked-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  &__checked {
    width: 20px;
    height: 20px;
    background: #51a2be;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    .checkline {
      position: relative;
      top: -2px;
      width: 30%;
      height: 50%;
      border-right: 2px solid white;
      border-bottom: 2px solid white;
      transform: rotate(45deg);
    }
  }
  .not-checked {
    background: var(--main-text-color-inverse);
    border-radius: 50%;
    border: 2px solid #fcbc03;
    .checkline {
      position: relative;
      top: -2px;
      width: 30%;
      height: 50%;
      border-right: 2px solid white;
      border-bottom: 2px solid white;
      transform: rotate(45deg);
    }
  }
  &__line {
    width: 4px;
    height: 20px;
    background: #51a2be;
  }
}
.status-block {
  background: var(--main-card-color);
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  border-radius: 6px;
  padding: 20px;
}
.active {
  background: var(--item-active-bg) !important;
  color: white;
  span {
    color: white !important;
    font-size: 12px;
  }
}
.my-request__item.manager {
  margin-bottom: 0;
  .manager-image {
    width: 60px;
  }
  .my-request__title {
    margin-bottom: 0;
    margin-left: 30px;
    width: 80%;
    font-size: 16px;
    line-height: 27px;
    text-align: start;
  }
}
.my-request__item {
  display: flex;
  min-height: 90px;
  max-width: 308px;
  //max-height: 80px;
  background: var(--main-item-bg);
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  border-radius: 6px;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  padding: 8px 0 8px 20px;
  h3 {
    margin-top: 0;
    font-size: 18px;
  }
  p {
    font-size: 14px;
    margin-bottom: 0;
    span {
      font-weight: 700;
    }
    .yellow {
      color: #fbbd3b;
    }
    .blue {
      color: #2378f9;
    }
  }
  .divider {
    width: 0;
    height: 100px;
    background: #e5f1f5;
    border: 3px solid #e5f1f5;
    border-radius: 5px;
    margin: 0 20px 0 10px;
  }

  // &:not(:last-child) {
  //   margin-right: 13px;
  // }
}
.my-request__item.title {
  max-width: 100%;
}
.chat-wrap {
  position: relative;
  display: flex;
  width: 100%;
  //height: 87vh;
  bottom: 10px;
  height: 100vh;
}

.chat {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin-left: 10px;
  border: 3px solid var(--main-card-color);
  border-bottom: 0;
  filter: drop-shadow(0 4px 25px rgba(0, 0, 0, 0.01));
  border-radius: 6px;
  padding: 0;
  height: 100%;
}

.chat__title-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;

  padding: 23px 27px 24px 32px;

  background: #ffffff;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  border-radius: 6px;
  height: 90px;
}

.chat__list {
  display: flex;
  flex-direction: column-reverse;
  //align-items: flex-end;
  overflow-y: auto;
  margin-bottom: 22px;
  padding: 22px 24px;
  flex: 1 0 auto;
  max-height: 65%;
}
.chat__list.manager {
  max-height: 75%;
}

.chat__bottom-panel {
  //position: relative;
  position: sticky;
  top: calc(100vh - 75px);
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  bottom: 0;
  flex: 0 0 auto;

  padding: 17px 27px;
  background: var(--main-card-color);
  border-radius: 0 0 25px 25px;
  transform: rotate(-180deg);
}

.chat__name {
  display: flex;
  flex-direction: column;
}

.chat__id {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;

  color: #006b90;
}

.chat__you-msg,
.chat__other-msg {
  padding: 14px 16px 14px 19px;
  border-radius: 18px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;
  margin-bottom: 12px;
  .file-icon {
    cursor: pointer;
    margin-right: 10px;
  }
}

.chat__you-msg {
  position: relative;
  background: #006b90;
  color: #ffffff;

  align-self: flex-end;
  max-width: 70%;

  &::after {
    content: "";
    position: absolute;
    bottom: -6px;
    right: -2px;
    transform: rotate(-19deg);

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 24px 24px 0;
    border-color: transparent #006b90 transparent transparent;
  }
}

.chat__other-msg {
  position: relative;
  background: #f1f0f5;
  color: #4b4b67;

  align-self: flex-start;
  max-width: 70%;

  &:last-child {
    &::after {
      content: "";
      position: absolute;
      bottom: -9px;
      right: 43px;
      transform: rotate(290deg);

      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 19px 19px 0;
      border-color: transparent #f1f0f5 transparent transparent;
    }
  }
}

.chat__make-msg {
  padding: 12px 19px 11px;
  width: 100%;
  background: var(--color-to-write-input-bg);
  border-radius: 18.5px;
  border: 0;
  height: 40px;
  outline: none;
  color: var(--main-text-color);
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;

  transform: rotate(180deg);

  &::placeholder {
    color: #cfcfcf;
  }
}

.chat__user {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: #4b4b67;
}

.chat__action {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  /* identical to box height */
  letter-spacing: 0.04em;

  color: #cfcfcf;
}

.chat-users {
  max-width: 310px;
  padding-right: 10px;
  margin-right: 10px;
}

.chat-users__item-wrap {
  display: flex;
}

.chat-users__list {
  margin: 0;
  padding: 0;
}

.chat-users__msg-item {
  position: absolute;
  top: 9px;
  right: 14px;
  width: 19px;
  height: 19px;
  border-radius: 50%;

  background: #51a2be;

  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.04em;

  color: #ffffff;
}

.chat-users__id-block {
  display: flex;
  flex-direction: column;
  //justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.chat-users__user-id {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;

  color: #006b90;
}

.chat-users__item {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px 14px 16px 17px;
  min-height: 74px;
  max-width: 308px;
  width: 100%;
  margin-bottom: 5px;

  background: #ffffff;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  border-radius: 6px;

  list-style: none;

  cursor: pointer;

  &_active {
    background: #51a2be;

    .chat-users__user-id,
    .chat-users__user-name {
      color: #ffffff;
    }

    .chat-users__action {
      color: rgba(255, 255, 255, 0.6);
    }

    .chat-users__msg-item {
      display: none;
    }
  }
}

.chat-users__user-name {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;

  color: #4b4b67;
}

.chat-users__action {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;

  color: #cfcfcf;
}

.chat-users__item-info {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.chat__attachment-block {
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-select {
  transform: rotate(180deg);
  cursor: pointer;
  display: flex;
  align-items: center;

  font-size: 12px;
  line-height: 14px;

  color: #1f1f1f;
}

.check-new-date {
  opacity: 0.5;
}
.chat__you-msg {
  .date-label {
    display: flex;
    justify-content: flex-end;
  }
}
.chat__other-msg {
  .date-label {
    display: flex;
    justify-content: flex-end;
  }
}
.date-label {
  font-size: 10px;
  opacity: 0.5;
}

.file-select > .select-button {
  background-image: url("../../resource/img/attach.svg");
  background-repeat: no-repeat;
  font-size: 10px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin-right: 17px;

  background-position: center;
  padding: 15px;
}

.file-select > input[type="file"] {
  display: none;
}

.help-icon {
  position: absolute;
}

.form-control {
  background-color: var(--main-card-color);
  color: var(--main-text-color);
}
</style>
